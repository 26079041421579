<template>
  <div>
    <profile v-if="actor" :actor="actor"> </profile>
  </div>
</template>
<script>
import Profile from "../../components/Profile.vue";
import { mapGetters } from "vuex";
export default {
  name: "GeneralInformation",
  components: { Profile },
  computed: {
    ...mapGetters("actors", ["actor"]),
  },
};
</script>
<style lang="scss" scoped></style>
